$.fn.hasAttr = function (name) {
	return this.attr(name) !== undefined;
};
var isMac = false;
if (navigator.platform.indexOf("Mac") > -1) {
	isMac = true;
	$("body").addClass("mac-user");
}
var isIphone = false;
if (navigator.userAgent.match(/iPhone/i) != null) {
	isIphone = true;
	$("body").addClass("mac-user");
}

// ******* Global Variable s*******
var currCulture = "vi";
if ($("html").attr("lang") == "en-US") currCulture = "en";
var lang = {};
lang["en"] = {
	night: " Night",
	month: "month ",
	day: "DAY",
	room: "ROOM",
	adult: "ADULT",
	kid: "KID",
};
lang["vi"] = {
	night: " Đêm",
	month: "tháng ",
	day: "NGÀY",
	room: "PHÒNG",
	adult: "NGƯỜI LỚN",
	kid: "TRẺ EM",
};

// *** Window
var ww = $(window).width();
var body = $("body");
// *** Header *** //
var header = $("header");
var height_header = header.outerHeight();
var main = $("main");
var menu_nav = header.find("nav");
var burger = header.find("#burger");
var overlay = $("#overlay");
// *** Nav Moble *** //
var nav_mobile = $(".nav-mobile");
var ul_Child = nav_mobile.find(".has-child");
var close_NB = nav_mobile.find("#close-button");
// *** Banner *** //
var pagesBanner = $("#page-banner");
var mainBanner = $("#main-banner");
// *** Footer *** //
var mainSection = $("main");
var footer = $("footer");
// *** Component *** //
// Search
var fixedItem = $("#fixed-item");
var btn_BackToTop = $("#backToTop");

function removeActiveElement() {
	burger.removeClass("active");
	nav_mobile.removeClass("active");
	overlay.removeClass("active");
	body.removeClass("ovh");
	header.removeClass("navMobileOpen");
	$(".search-overlay").removeClass("active");
}
const headerFunction = {
	onScroll: () => {
		var lastScrollTop = 0;
		$(window).on("scroll", function () {
			var st = $(this).scrollTop();
			if (st > lastScrollTop && st > height_header) {
				header.addClass("header-scroll");
				$(".search-overlay").removeClass("active");
				$("header .tools").removeClass("search-active");

				// height_header = header.find(".bottom").outerHeight();
			} else {
				header.removeClass("header-scroll");
				// height_header = header.outerHeight();
			}
			lastScrollTop = st;
			// removeSearch();
		});
	},
	navMobileSlide: () => {
		burger.on("click", function () {
			burger.toggleClass("active");
			nav_mobile.toggleClass("active");
			overlay.toggleClass("active");
			body.toggleClass("ovh");
			header.toggleClass("navMobileOpen");
			$(".search-overlay").removeClass("active");
		});
		overlay.on("click", function () {
			removeActiveElement();
		});
	},
	navMobileChild: () => {
		var liChild = nav_mobile.find("li.menu-item-has-children > a");
		//
		liChild.each(function () {
			$(this).attr("state", "unClick");
		});
		liChild.on("click", function (e) {
			if ($(this).attr("state") === "unClick") {
				e.preventDefault();
				console.log("unclick");
				$(this)
					.closest("ul")
					.find("li.menu-item-has-children > a")
					.attr("state", "unClick");
				$(this)
					.closest("ul")
					.find("li.menu-item-has-children > a")
					.siblings()
					.slideUp();
				$(this).attr("state", "click");
				$(this).siblings().slideDown();
			} else {
				console.log("click, page direction to" + $(this).attr("href"));
			}
		});
	},
	searchActive: () => {
		//
		$(".search-overlay").on("click", function () {
			$(".search-overlay").addClass("active");
			$("header .top").addClass("search-active");
			// header.addClass("navMobileOpen");
			setTimeout(() => {
				$(".search-overlay .searchbox input").trigger("focus");
			}, 500);
		});
		$(".search-overlay .searchbox input").on("focus", function () {
			$(this).parent().addClass("active");
		});
		$(".search-overlay .searchbox input").on("focusout", function () {
			$(this).parent().removeClass("active");
		});
		$(document).on("click", function (event) {
			var $trigger = $("header");
			var $trigger_2 = $(".search-overlay");
			if (
				$trigger !== event.target &&
				!$trigger.has(event.target).length &&
				$trigger_2 !== event.target &&
				!$trigger_2.has(event.target).length
			) {
				$(".search-overlay").removeClass("active");
				header.removeClass("navMobileOpen");
				$("header .tools").removeClass("search-active");
			}
		});
		$(".search-overlay .searchinput").keyup(function () {
			$(".search-overlay .wrapper").addClass("fixed-title");
			if ($(this).val() == "") {
				$(".search-overlay .wrapper").removeClass("fixed-title");
			}
		});
		// Press ESC to close Search
		setTimeout(() => {
			$(window).on("keyup", function (event) {
				var e = event.keyCode;
				if (e == 27) {
					$(".search-overlay").removeClass("active");
					$("header .tools").removeClass("search-active");
				}
			});
		}, 500);

		// Trigger click with 2 button
		$(".search-overlay .searchbox input[type='text']").keypress(function (
			n
		) {
			if (n.keyCode == 13)
				$(".searchbox button").trigger("click"), console.log("click");
		});
	},
	escBtn: () => {
		$(window).on("keyup", function (event) {
			var e = event.keyCode;
			if (e == 27) {
				removeActiveElement();
			}
		});
	},
	init: () => {
		headerFunction.onScroll();
		headerFunction.navMobileSlide();
		headerFunction.navMobileChild();
		headerFunction.searchActive();
		headerFunction.escBtn();
	},
};

// *** Video Variable
var main_banner;
function swiperInit() {
	main_banner = new Swiper("#main-banner .swiper", {
		spaceBetween: 0,
		effect: "creative",
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-20%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		speed: 500,
		observer: true,
		observeParents: true,
		pagination: {
			el: "#main-banner .swiper-pagination",
			clickable: true,
		},
		navigation: {
			prevEl: "#main-banner .swiper-button-prev",
			nextEl: "#main-banner .swiper-button-next",
		},
	});

	$("#main-banner .count-down").each(function () {
		//
		var e = $(this);
		var dataTime = $(this).attr("data-time");
		if (dataTime != "") {
			var countDownDate = new Date(dataTime).getTime();
			var x = setInterval(function () {
				var now = new Date().getTime();
				var distance = countDownDate - now;
				var days = Math.floor(distance / (1000 * 60 * 60 * 24));
				var hours = Math.floor(
					(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				var minutes = Math.floor(
					(distance % (1000 * 60 * 60)) / (1000 * 60)
				);
				var seconds = Math.floor((distance % (1000 * 60)) / 1000);

				e.find(".days .value").text(days);
				e.find(".hours .value").text(hours);
				e.find(".minutes .value").text(minutes);
				e.find(".seconds .value").text(seconds);
				if (distance < 0) {
					clearInterval(x);
					// document.getElementById("demo").innerHTML = "EXPIRED";
				}
			}, 1000);
		} else {
			$(this).remove();
		}
	});

	var index_3 = new Swiper(".index-3-slide .swiper", {
		speed: 500,
		spaceBetween: 10,
		loop: true,
		navigation: {
			prevEl: ".index-3-slide .swiper-button-prev",
			nextEl: ".index-3-slide .swiper-button-next",
		},
		pagination: {
			el: ".index-3-slide .swiper-pagination",
			clickable: true,
		},
		slidesPerView: 1,
	});

	var index_4 = new Swiper(".index-4-slide .swiper", {
		speed: 500,
		spaceBetween: 0,

		loop: true,
		navigation: {
			prevEl: ".index-4-slide .swiper-button-prev",
			nextEl: ".index-4-slide .swiper-button-next",
		},
		pagination: {
			el: ".index-4-slide .swiper-pagination",
			type: "fraction",
		},
		breakpoints: {
			1280: {
				slidesPerView: 5,
				centeredSlides: true,
			},
			1024: {
				slidesPerView: 3,
				centeredSlides: true,
			},
			576: {
				slidesPerView: 2,
			},
			320: {
				slidesPerView: 1,
			},
		},
		on: {
			afterInit: () => {
				$(".index-4 .swiper-slide-active .order-contact-box").addClass(
					"on-swiper-active"
				);
			},
			slideChange: () => {
				$(".index-4 .swiper-slide .order-contact-box").removeClass(
					"on-swiper-active"
				);
			},
			slideChangeTransitionStart: () => {
				$(".index-4 .swiper-slide-active .order-contact-box").addClass(
					"on-swiper-active"
				);
			},
		},
	});
	var index_5 = new Swiper(".index-5 .swiper", {
		speed: 500,
		loop: true,
		navigation: {
			prevEl: ".index-5 .swiper-button-prev",
			nextEl: ".index-5 .swiper-button-next",
		},
		breakpoints: {
			1280: {
				slidesPerView: 5,
				spaceBetween: 30,
				centeredSlides: true,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 30,
				centeredSlides: true,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 15,
				centeredSlides: true,
			},
			576: {
				slidesPerView: 2,
			},
			320: {
				slidesPerView: 2,
			},
		},
	});

	$(".popup-2").each(function (e) {
		$(this).addClass("popup-2-slide-" + e);
		var popup_2_thumb = new Swiper(`.popup-2-slide-${e} .thumb .swiper`, {
			speed: 500,
			observer: true,
			observeParents: true,
			allowTouchMove: false,
			spaceBetween: 0,
			slidesPerView: 1,
		});

		var popup_2_main = new Swiper(`.popup-2-slide-${e} .main .swiper`, {
			speed: 500,
			observer: true,
			observeParents: true,
			spaceBetween: 0,
			slidesPerView: 1,
			thumbs: {
				swiper: popup_2_thumb,
			},
			navigation: {
				prevEl: `.popup-2-slide-${e} .swiper-button-prev`,
				nextEl: `.popup-2-slide-${e} .swiper-button-next`,
			},
			pagination: {
				el: `.popup-2-slide-${e} .swiper-pagination`,
				type: "fraction",
			},
		});
	});
}

function autoSlideNextOnThumbSlide(initSwiper, element, thumb) {
	let activeIndex = initSwiper.activeIndex + 1;
	let activeSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex})`
	);
	let nextSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex + 1})`
	);
	let prevSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex - 1})`
	);

	if (nextSlide && !nextSlide.classList.contains("swiper-slide-visible")) {
		initSwiper.thumbs.swiper.slideNext();
	} else if (
		prevSlide &&
		!prevSlide.classList.contains("swiper-slide-visible")
	) {
		initSwiper.thumbs.swiper.slidePrev();
	}
}

function expandItem() {
	// Filter Load
	var hasLoadBtn = $("[has-expand-btn]");
	hasLoadBtn.each(function () {
		var $this = $(this);
		var expandBtn = $(this).find(".expand-btn");
		var list = $(this).find(".expand-item").length;
		var count;
		var countMobile = $(this).attr("item-count-mobile");
		countMobile != undefined && countMobile != 0 && ww < 576
			? (count = Number($(this).attr("item-count-mobile")))
			: (count = Number($(this).attr("item-count")));
		var expand = Number($(this).attr("item-expand"));

		//=// Init
		function init(initValue, thisFunction) {
			if (list >= initValue) {
				thisFunction
					.find(".expand-item")
					.slice(0, initValue)
					.slideDown({
						complete: function () {
							$(this).addClass("done-animated");
						},
					});
			} else {
				thisFunction.find(".expand-item").slideDown();
				thisFunction.find(".expand-item").addClass("done-animated");
			}
			if (list == 0 || list <= initValue) {
				expandBtn.hide();
			}
		}
		init(count, $this);

		// Click
		function expandBtnInit(initCount, thisFunction) {
			count = initCount + expand <= list ? initCount + expand : list;
			thisFunction
				.closest(hasLoadBtn)
				.find(".expand-item")
				.slice(0, count)
				.slideDown({
					complete: function () {
						$(this).addClass("done-animated");
					},
				});
			// console.log(count);
			if (count == list) {
				thisFunction.slideUp();
			}
		}
		expandBtn.on("click", function (e) {
			e.preventDefault();
			expandBtnInit(count, $(this));
		});
	});
}

const globalJS = {
	autoFillNoImage: () => {
		setTimeout(() => {
			$("img").each(function () {
				if ($(this).hasAttr("data-src")) {
					if ($(this).attr("data-src") === "") {
						$(this).attr("data-src", "/no-image.png");
						$(this).attr("src", "/no-image.png");
						$(this).addClass("noImage");
					}
				} else if ($(this).hasAttr("src")) {
					if ($(this).attr("src") === "") {
						$(this).attr("src", "/no-image.png");
						$(this).addClass("noImage");
					}
				}
			});
		}, 150);
	},
	loopAttributeForNavMenu: () => {
		header.find("nav li").each(function (e) {
			e++;
			$(this).attr("data-scroll", `index-${e}`);
		});
	},
	autoAppend: () => {
		var appendId = $("[id*='autoAppend-']");
		appendId.each(function (e) {
			var id = $(this).attr("id").slice(11);
			// console.log(id);
			$(this).appendTo("[id*='autoAppendHere-" + id + "']");
			var curHeight = $(this).get(0).scrollHeight;
		});
	},
	autoClone: () => {
		var cloneId = $("[id*='autoClone-']");
		cloneId.each(function (e) {
			var id = $(this).attr("id").slice(10);
			// console.log(id);
			$(this)
				.clone()
				.appendTo("[id*='autoCloneHere-" + id + "']");
		});
	},
	setBackground: () => {
		var position = ["", "top", "left", "right", "bottom"];
		jQuery.each(position, function (index, item) {
			//
			//
			$(`[setBackground${item}]`).each(function () {
				var background = $(this).attr("setBackground" + item);
				$(this).css({
					"background-size": "cover",
					"background-position": `${item} center`,
					"background-image": `url(${background})`,
				});
			});
		});
	},
	scrollToTop: () => {
		$(window).on("scroll", function () {
			if (
				$(this).scrollTop() >= mainBanner.outerHeight() ||
				$(this).scrollTop() >= pagesBanner.outerHeight()
			) {
				fixedItem.addClass("active");
			} else {
				fixedItem.removeClass("active");
			}
		});
		btn_BackToTop.on("click", function () {
			$("html,body").animate({
				scrollTop: 0,
			});
		});
	},
	inputButtonSubmit: () => {
		$("input[type='submit']").each(function () {
			if ($(this).parent().is(".btn")) {
				//
				$(this).parent().addClass("has-input-submit");
				$(this).parent().attr("data-title", $(this).val());
			}
		});
	},
	checkListItem: () => {
		$("main ul").each(function () {
			$(this).addClass("list-item-added");
		});
	},
	init: () => {
		globalJS.autoFillNoImage();
		globalJS.loopAttributeForNavMenu();
		globalJS.autoAppend();
		globalJS.autoClone();
		globalJS.setBackground();
		globalJS.scrollToTop();
		globalJS.inputButtonSubmit();
		globalJS.checkListItem();
	},
};

function counterUpNumber() {
	$(".counter").counterUp({
		delay: 10,
		time: 1000,
	});
}

function activeCommonNav() {
	$(".common-nav li").each(function () {
		if ($(this).hasClass("active")) {
			$(this).closest("ul").scrollLeft($(this).position().left);
		}
	});
}

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function setElementToScreen() {
	var screenRight = $(".screen-right");
	var screenLeft = $(".screen-left");
	const position = ($(window).width() - $(".container").width()) / 2;
	if ($(window).width() >= 1280) {
		if (screenRight.length) {
			//
			screenRight.each(function () {
				$(this).css({
					"margin-right": -position + "px",
				});
			});
		}
		if (screenLeft.length) {
			//
			screenLeft.each(function () {
				$(this).css({
					"margin-left": -position + "px",
				});
			});
		}
	}
}

var delayLayoutElement = [
	".nav-mobile nav > ul > li",
	".index-10 .list .title,.index-10 .list .item",
];
const setDelayLayoutElement = () => {
	jQuery.each(delayLayoutElement, function (index, item) {
		$(`body ${item}`).each(function (e) {
			e += 1;
			$(this).css({
				"transition-delay": e * 0.1 + "s",
			});
		});
	});
};

const componentsAnimation = {
	orderContactBox: () => {
		if (ww >= 1280) {
			$(".order-contact-box").hover(
				function () {
					$(this).addClass("on-hover");
				},
				function () {
					$(this).removeClass("on-hover");
				}
			);
		} else {
			$(".order-contact-box").each(function () {
				$(this).addClass("stay-hover");
			});
		}
	},
	animationProp: () => {
		if (ww >= 1280) {
			gsap.registerPlugin(ScrollTrigger);

			// Index 1
			// Cloud
			gsap.to(".index-1 .cloud-1", {
				scrollTrigger: {
					trigger: ".index-1",
					scrub: true,
					// markers: true,
					endTrigger: ".index-1",
				},
				x: "-=30%",
				duration: 0.3,
			});

			gsap.to(".index-1 .cloud-2", {
				scrollTrigger: {
					trigger: ".index-1",
					scrub: true,
					// markers: true,
					endTrigger: ".index-1",
				},
				x: "+=30%",
				duration: 1,
			});

			gsap.to(".index-1 .image-cover .background", {
				scrollTrigger: {
					trigger: ".index-1",
					start: "top",
					scrub: true,
					endTrigger: ".index-1",
					// markers: true,
				},
				y: "+=20%",
				scale: 1.1,
				duration: 2,
			});

			var opacity_0_top_50 = {
				y: "+=50%",
				opacity: 0,
				duration: 1.5,
			};

			var image_index_1_row_1 = gsap.timeline({
				//
				scrollTrigger: {
					trigger: ".index-1",
					start: "top center",
					scrub: true,
					// markers: true,
					end: "top",
					endTrigger: ".index-1",
				},
			});
			var image_index_1_row_1_element = [
				".index-1 .box-right .img",
				".index-1 .box-left .logo-80",
				".index-1 .box-left .title-wrapper",
				".index-1 .box-left .content",
			];
			image_index_1_row_1_element.forEach(function (obj, i) {
				image_index_1_row_1.from(obj, {
					...opacity_0_top_50,
				});
			});

			var image_index_1_row_2 = gsap.timeline({
				//
				scrollTrigger: {
					trigger: ".index-1 .box-left",
					start: "top center",
					scrub: true,
					// markers: true,
					end: "bottom +=50%",
					endTrigger: ".index-1 .box-left",
				},
			});
			var image_index_1_row_2_element = [
				".index-1 .box-left .image-wrapper",
				".index-1 .box-right .content",
			];
			image_index_1_row_2_element.forEach(function (obj, i) {
				image_index_1_row_2.from(obj, {
					...opacity_0_top_50,
				});
			});
			// End Index 1

			// Index 2
			var index_2_content = [
				".index-2 .absolute-logo",
				".index-2 .logo-80",
				".index-2 .medium-title",
				".index-2 .description",
				".index-2 .button",
			];
			index_2_content.forEach(function (obj, i) {
				gsap.from(obj, {
					scrollTrigger: {
						trigger: ".index-2",
						start: "top center",
						scrub: true,
						// markers: true,
						end: "center center",
						endTrigger: obj,
					},
					y: "+=50%",
					autoAlpha: 0,
				});
			});

			gsap.from(".index-2 .time-line-tree", {
				scrollTrigger: {
					trigger: ".index-2 .grid",
					start: "top center",
					scrub: true,
					// markers: true,
					end: "bottom",
					endTrigger: ".index-2 .grid",
					onUpdate: (self) => {
						var percent = self.progress * 100;
						var percentRadius = 100 + self.progress * 100;
						$(".index-2 .time-line-tree").css(
							"--data-percent",
							percent + "%"
						);
					},
				},
			});

			// var gridItemIndex2 = [];
			// $(".index-2 .grid").each(function () {
			// 	gridItemIndex2.push({
			// 		time: $(this).find(".time-line"),
			// 		content: $(this).find(".content"),
			// 		id: $(this).find(".id"),
			// 	});
			// });

			// gridItemIndex2.forEach((obj, i) => {
			// 	var id = obj.id[0];
			// 	var left = obj.time[0];
			// 	var right = obj.content[0];
			// 	gsap.from(id, {
			// 		autoAlpha: 0,
			// 		scale: 0.5,
			// 	});

			// 	gsap.to(id, {
			// 		scrollTrigger: {
			// 			trigger: id,
			// 			scrub: true,
			// 			end: "center center",
			// 		},
			// 		scale: 1,
			// 		autoAlpha: 1,
			// 		duration: 0.5,
			// 	});

			// 	gsap.from(left, {
			// 		x: `-=5%`,
			// 		autoAlpha: 0,
			// 	});
			// 	gsap.to(left, {
			// 		scrollTrigger: {
			// 			trigger: left,
			// 			scrub: true,
			// 			end: "top +50%",
			// 		},
			// 		x: "0",
			// 		autoAlpha: 1,
			// 		duration: 0.5,
			// 	});

			// 	gsap.from(right, {
			// 		x: `+=5%`,
			// 		autoAlpha: 0,
			// 	});
			// 	gsap.to(right, {
			// 		scrollTrigger: {
			// 			trigger: right,
			// 			scrub: true,
			// 			end: "top +50%",
			// 		},
			// 		x: "0",
			// 		autoAlpha: 1,
			// 		duration: 0.5,
			// 	});
			// });

			var index_3_content = [
				".index-3 .logo-80",
				".index-3 .medium-title",
			];
			index_3_content.forEach(function (obj, i) {
				gsap.from(obj, {
					scrollTrigger: {
						trigger: ".index-3",
						start: "top center",
						scrub: true,
						// markers: true,
						endTrigger: obj,
						end: "bottom +=30%",
					},
					y: "+=50%",
					autoAlpha: 0,
				});
			});
			var gridItemIndex3 = [];
			$(".index-3 .news-list-grid .bn").each(function () {
				gridItemIndex3.push({
					parent: $(this),
					img: $(this).find(".img"),
					title: $(this).find(".title"),
				});
			});

			gridItemIndex3.forEach((obj, i) => {
				var parent = obj.parent[0];
				var img = obj.img[0];
				var title = obj.title[0];
				gsap.to(parent, {
					scrollTrigger: {
						trigger: ".index-3",
						start: "top center",
						scrub: false,
						// markers: true,
						end: "center 55%",
						endTrigger: ".index-3 .news-list-grid",
						onUpdate: (self) => {
							var percent = self.progress * 100;
							var percentRadius = 100 + self.progress * 100;
							if (percent == 100) {
								self.disable();
								$(parent).addClass("done-animation");
							} else {
								$(parent).css("--data-percent", percent + "%");
								$(parent).css(
									"--data-radius",
									percentRadius + "%"
								);
							}
						},
					},
				});
			});

			var index_4_content = [
				".index-4 .logo-80",
				".index-4 .medium-title",
				".index-4 .des",
				".index-4 .tips",
			];
			index_4_content.forEach(function (obj, i) {
				gsap.from(obj, {
					scrollTrigger: {
						trigger: ".index-4",
						start: "top center",
						scrub: true,
						// markers: true,
						endTrigger: obj,
						end: "bottom +=30%",
					},
					y: "+=50%",
					autoAlpha: 0,
				});
			});
			gsap.from(".index-4 .swiper", {
				scrollTrigger: {
					trigger: ".index-4",
					start: "top center",
					// scrub: false,
					// markers: true,
					endTrigger: ".index-4 .tips",
					end: "top",
				},
				y: "+=50%",
				autoAlpha: 0,
			});

			var index_5_content = [
				".index-5 .logo-80",
				".index-5 .medium-title",
				".index-5 .index-5-slide",
			];
			index_5_content.forEach(function (obj, i) {
				gsap.from(obj, {
					scrollTrigger: {
						trigger: ".index-5",
						start: "top center",
						scrub: true,
						// markers: true,
						endTrigger: obj,
						end: "bottom +=30%",
					},
					y: "+=50%",
					autoAlpha: 0,
				});
			});

			var index_6_content = [
				".index-6 .logo-80",
				".index-6 .medium-title",
				".index-6 .search-faq",
			];
			index_6_content.forEach(function (obj, i) {
				gsap.from(obj, {
					scrollTrigger: {
						trigger: ".index-6",
						start: "top center",
						scrub: true,
						// markers: true,
						endTrigger: obj,
						end: "bottom +=30%",
					},
					y: "+=50%",
					autoAlpha: 0,
				});
			});

			var index_7_content = [
				".index-7 .logo-80",
				".index-7 .medium-title",
			];
			index_7_content.forEach(function (obj, i) {
				gsap.from(obj, {
					scrollTrigger: {
						trigger: ".index-7",
						start: "top center",
						scrub: true,
						// markers: true,
						endTrigger: obj,
						end: "top",
					},
					y: "+=50%",
					autoAlpha: 0,
				});
			});
			gsap.to(".index-7 .image-wrapper", {
				scrollTrigger: {
					trigger: ".index-7",
					start: "top center",
					// scrub: true,
					// markers: true,
					endTrigger: ".index-7 .wrapper",
					end: "top",
					onUpdate: (self) => {
						var percent = self.progress * 100;
						if (percent == 100) {
							self.disable();
							$(".index-7 .wrapper").addClass("done-animation");
						} else {
							$(".index-7 .wrapper").css(
								"--data-percent",
								percent + "%"
							);
						}
					},
				},
			});
		}
	},
	init: () => {
		componentsAnimation.orderContactBox();
		componentsAnimation.animationProp();
	},
};

const activeOnScroll = () => {
	height_header = height_header - header.find(".top").outerHeight();
	// Onload
	header.find(".bottom li").eq(0).addClass("active");
	function OnLoadToSection() {
		var path = window.location.href;
		let destination = "#" + path.substr(path.indexOf("#") + 1);
		// console.log(destination);
		$("html, body").animate(
			{
				scrollTop: $(destination).offset().top + 10,
			},
			"slow"
		);
	}
	// Check if Destination have #sec-
	if (window.location.href.indexOf("index") != -1) {
		OnLoadToSection();
	}

	header.find(".bottom li").on("click", function () {
		header.find(".bottom li").removeClass("active");
		$(this).addClass("active");
	});
	// Parent Nav Click
	// Scroll Active
	function getDocHeight() {
		var D = document;
		return Math.max(
			D.body.scrollHeight,
			D.documentElement.scrollHeight,
			D.body.offsetHeight,
			D.documentElement.offsetHeight,
			D.body.clientHeight,
			D.documentElement.clientHeight
		);
	}
	$(window).on("scroll", function () {
		var scrollDistance = $(this).scrollTop();

		$(".homepage section").each(function (i) {
			if ($(this).position().top - height_header <= scrollDistance) {
				header.find(".bottom li").removeClass("active");
				header.find(".bottom li").eq(i).addClass("active");
			}
			if (scrollDistance + $(window).height() == getDocHeight()) {
				header.find(".bottom li").removeClass("active");
				header.find(".bottom li").last().addClass("active");
			}
		});
	});

	header.find(".bottom li").on("click", function () {
		var target = $(this).attr("data-scroll");
		$("html, body").animate(
			{
				scrollTop: $("#" + target).offset().top,
			},
			500
			// function () {
			// 	location.hash = target;
			// }
		);
		header.find(".bottom li").removeClass("active");
		$(this).addClass("active");
	});
	header.find(".nav-mobile li").on("click", function () {
		var target = $(this).attr("data-scroll");
		$("html, body").animate(
			{
				scrollTop:
					$("#" + target).offset().top -
					header.find(".top").outerHeight(),
			},
			500
			// function () {
			// 	location.hash = target;
			// }
		);
		header.find(".nav-mobile li").removeClass("active");
		$(this).addClass("active");
		removeActiveElement();
	});
};

function fancyboxCustom() {
	Fancybox.bind("[data-fancybox-custom]", {
		mainClass: "custom-fancybox-popup",
		template: {
			closeButton: '<i class="fa-light fa-circle-xmark"></i>',
		},
		parentEL: document.head,
		on: {
			done: function () {
				$(".booking-form").appendTo(
					".fancybox__container .popup-2 .section-2 .container"
				);
				$('input[name="ezbe-dates"]').trigger("click");
				function daysDiff(d1, d2) {
					var diff = d2.getTime() - d1.getTime();
					return diff / (1000 * 60 * 60 * 24);
				}
				function minTwoDigits(n) {
					return (n < 10 ? "0" : "") + n;
				}
				$(".calendar-table").on("mousedown", function () {
					setTimeout(() => {
						var value = $("input[name='ezbe-dates']").val();
						var dateArray = [];
						dateArray = [];
						dateArray.push(value.split(">"));
						var newDateArray = dateArray[0];
						if (newDateArray.length > 1) {
							var [date1, date2] = newDateArray;
							date1 = date1.replaceAll("-", "/");
							date2 = date2.replaceAll("-", "/");
							console.table(date1, date2);
							var newDate1 = new Date(date1);
							var newDate2 = new Date(date2);
							var daysBetween = daysDiff(newDate1, newDate2);
							var date1_obj = {
								day: minTwoDigits(newDate1.getDate()),
								month: newDate1.getMonth() + 1,
								year: newDate1.getFullYear(),
							};
							var date2_obj = {
								day: minTwoDigits(newDate2.getDate()),
								month: newDate2.getMonth() + 1,
								year: newDate2.getFullYear(),
							};
							var bookingForm = $(
								".booking-form .custom-content-wrapper"
							);
							bookingForm.find(".diffDay span").text(daysBetween);
							daysBetween > 1 && currCulture == "en"
								? bookingForm
										.find(".diffDay em")
										.text(lang[currCulture].night + "s")
								: bookingForm
										.find(".diffDay em")
										.text(lang[currCulture].night);

							// Date 1 HTML
							bookingForm
								.find(".date-1 .day")
								.text(date1_obj.day);
							bookingForm
								.find(".date-1 .month span")
								.text(date1_obj.month);
							bookingForm
								.find(".date-1 .year")
								.text(date1_obj.year);

							// Date 2 HTML
							bookingForm
								.find(".date-2 .day")
								.text(date2_obj.day);
							bookingForm
								.find(".date-2 .month span")
								.text(date2_obj.month);
							bookingForm
								.find(".date-2 .year")
								.text(date2_obj.year);
						}
					}, 300);
				});

				// Prevent close fancybox on calendar prev - next
				function preventCloseFancybox() {
					$(".datepicker-wrapper thead .next").on(
						"click",
						function (e) {
							e.preventDefault();
							console.log("asd");
						}
					);
					$(".datepicker-wrapper thead .prev").on(
						"click",
						function (e) {
							e.preventDefault();
							console.log("asd");
						}
					);
				}
				setInterval(() => {
					preventCloseFancybox();
				}, 50);
			},
			closing: function () {
				$(".booking-form").appendTo(".hidden-booking");
				// Reset booking form
				$('input[name="ezbe-dates"]')
					.data("daterangepicker")
					.setStartDate(moment());
				$('input[name="ezbe-dates"]')
					.data("daterangepicker")
					.setEndDate(moment());
				$(".cancelBtn").trigger("click");
				$(".booking-form input[type='text']").val("");
				$(".booking-form .diffDay span").text("0");
				$(".booking-form input").trigger("change");
				$(".booking-form .custom-content-wrapper .bottom .date").each(
					function () {
						$(this).find(".day").text("00");
						$(this).find(".month span").text("01");
						$(this).find(".year").text("2022");
					}
				);
			},
		},
	});

	Fancybox.bind("[data-fancybox-term]", {
		mainClass: "term-fancybox",
		template: {
			closeButton: '<i class="fa-light fa-circle-xmark"></i>',
		},
		parentEL: document.head,
	});
}

function customForm() {
	var inputDate = $(".form-group.form-date");
	inputDate.each(function () {
		$(this).find("input").prop("type", "text");
	});
	inputDate.find("input").on("focus", function () {
		$(this).prop("type", "date");
	});
}

function accordion() {
	$(".collapse-item .top").on("click", function () {
		console.log($(this));
		$(this).parent().toggleClass("active");
		$(this).siblings().slideToggle();
	});
}

function popupTermLayout() {
	var popup = $(".popup-term");
	popup.each(function () {
		var thisPopup = $(this);
		var content = thisPopup.find(".article-content .wrapper");
		content.each(function () {
			if (content.length <= 5) {
				thisPopup.find(".read-more").hide();
			} else {
				thisPopup.find(".article-content .wrapper").slice(-5).hide();
				thisPopup.find(".article-content .wrapper").slice(0, 5).show();
			}
		});
		var btn = thisPopup.find(".read-more");
		btn.on("click", function () {
			$(this).closest(".article").find(".wrapper").show();
			$(this).hide();
		});
	});
}

function styleCustomFormEZ() {
	$(".daterangepicker").wrapInner("<div class='datepicker-wrapper'></div>");
	$(".daterangepicker").appendTo(".booking-form");
	// * Initial Dome * //
	// Col 1 - Ngày
	var booKingFormCol = $(".booking-form .ezbe-form .ezbe-col-12");
	booKingFormCol
		.eq(0)
		.append(
			`<div class="custom-content-wrapper"><div class="top"><div class="value">${lang[currCulture].day}</div><div class="diffDay"><span>0</span><em>${lang[currCulture].night}</em></div></div><div class="bottom"><div class="date date-1"><div class="left"><div class="day big-number">00</div></div><div class="right"><div class="month">${lang[currCulture].month}<span>01</span></div><div class="year">2022</div></div></div><div class="date date-2"><div class="left"><div class="day big-number">00</div></div><div class="right"><div class="month">${lang[currCulture].month}<span>01</span></div><div class="year">2022</div></div></div></div></div>`
		);

	// Col 2 - 3 - 4 - Số phòng - Người lớn - Trẻ em
	for (var i = 1; i < 4; i++) {
		var content;
		if (i == 1) content = lang[currCulture].room;
		if (i == 2) content = lang[currCulture].adult;
		if (i == 3) content = lang[currCulture].kid;
		booKingFormCol
			.eq(i)
			.append(
				`<div class="custom-content-wrapper custom-select"><div class="title">${content}</div><div class="value">1</div></div>`
			);
		booKingFormCol.eq(i).find("select option").removeAttr("selected");
		booKingFormCol
			.eq(i)
			.find("select option")
			.eq(1)
			.attr("selected", "selected");
		booKingFormCol.eq(i).find("select").trigger("change");

		booKingFormCol
			.eq(i)
			.find("select")
			.appendTo(
				booKingFormCol
					.eq(i)
					.find(".custom-content-wrapper.custom-select")
			);
		booKingFormCol
			.eq(i)
			.find(".value")
			.text(booKingFormCol.eq(i).find("select").val());
	}
	$("body").on(
		"change",
		".custom-content-wrapper.custom-select select",
		function () {
			var value = $(this).val();
			$(this).siblings(".value").text(value);
		}
	);
}

$(document).ready(function () {
	// ---***--- Global JS ---***---
	globalJS.init();

	// ---***--- Header ---***---
	headerFunction.init();

	// ---***--- Body ---***---
	swiperInit();
	counterUpNumber();
	expandItem();
	activeCommonNav();
	setElementToScreen();
	setDelayLayoutElement();
	componentsAnimation.init();
	accordion();
	// ---***--- Form ---***---
	customForm();
	// ---***--- Fancybox ---***---
	fancyboxCustom();
	popupTermLayout();

	// if ($(".homepage").length && ww >= 1280) {
	// 	window.onbeforeunload = function () {
	// 		window.scrollTo(0, 0);
	// 	};
	// }
});

$(window).resize(function () {
	height_header = header.outerHeight();
	$("html").css({
		"--header-height": header.outerHeight() + "px",
	});
});
$(window).trigger("resize");

// ***** Preload ***** //
document.onreadystatechange = () => {
	if (document.readyState === "complete") {
		/**
		 * Turn on when Deploy
		 */

		$("html").css({
			"--header-height": header.outerHeight() + "px",
		});
		header.addClass("initial-header");
		main.addClass("initial-main");
		footer.addClass("initial-footer");
		activeOnScroll();
		setTimeout(() => {
			main_banner.params.autoplay.delay = 4500;
			main_banner.autoplay.run();

			styleCustomFormEZ();
		}, 1500);
	}
};
